import { NUMBER } from "../../../constants/app-constants";
import { KM_RANGE, TRANSMISSION_TYPES } from "../../../constants/seller-constant";
import { findStep } from "../../../utils/funnel/stepper";
import { STEP_NAMES, TABS, TAB_DATA } from "../../../utils/funnel/steps.mobile";
import { specialCases } from "./constant";

export const getTransmissionType = (transmissionType) => {
    if (!transmissionType) return "";
    return transmissionType === "AT" ? TRANSMISSION_TYPES.AT : TRANSMISSION_TYPES.MT;
};

export const getKmsRange = (kilometerDriven) => {
    if (!kilometerDriven) return "";
    const kmsRange = KM_RANGE.find(kms => parseInt(kms.value) === parseInt(kilometerDriven));
    return kmsRange ? kmsRange.label : "";
};

export const isDataChanged = (before, after) => {
    return before !== after;
};

export const getStepindex = (currentStep, tabs = TABS) => {
    const currentTab = tabs.filter(tab =>
        currentStep.toLowerCase().includes(tab.name.toLowerCase())
    );
    return currentTab[0].index;
};

// eslint-disable-next-line max-statements, complexity
export const resetTabValues = (currentStepIndex, { tabData = TAB_DATA, registrationNo }) => {
    const tabList = Object.keys(tabData);
    const precedingValues = {};
    for (let index = currentStepIndex; index <= tabList.length; index++) {
        const stepName = tabList[index - 1];
        if (stepName === STEP_NAMES.State && registrationNo && !registrationNo.userCarRegistrationNo) {
            precedingValues[stepName] = { displayText: "", id: null };
        } else {
            precedingValues[stepName] = specialCases[stepName] || { displayText: "", id: null };
        }

        if (currentStepIndex < NUMBER.FIVE) {
            precedingValues[STEP_NAMES.FuelType] = specialCases[STEP_NAMES.FuelType] ||  { displayText: "" };
            precedingValues[STEP_NAMES.Transmission] = specialCases[STEP_NAMES.Transmission] ||  { displayText: "" };
            precedingValues.variantActiveScreen = "fueltype";
        }
    }

    return { ...precedingValues, activateLastStep: null, askUserIntent: null, userIntent: null };
};

export const updateLeadFormState = ({ state, previousStep, value, nextStep, tabs, tabData, registrationNo, resetValues = true }) => {
    let currentStepIndex = state.currentStepIndex;
    let resettedTabValues = {};
    let dataChanged;
    if (state && state[previousStep]) { dataChanged = isDataChanged(state[previousStep].id, value.id); }
    let proceedCTA = state.proceedCTA;
    if (dataChanged) {
        proceedCTA = false;
        currentStepIndex = getStepindex(nextStep, tabs);
        resettedTabValues = resetTabValues(currentStepIndex, { tabData, registrationNo });
    }
    return {
        ...state,
        [previousStep]: value,
        ...(resetValues && resettedTabValues),
        currentStepIndex,
        proceedCTA,
        isLeadFormChanged: dataChanged
    };
};

export const LEAD_FORM_STEPS = [
    STEP_NAMES.KilometerDriven,
    STEP_NAMES.Kms,
    STEP_NAMES.RTO,
    STEP_NAMES.FuelType,
    STEP_NAMES.Transmission
];

const LEAD_FORM_VARIANT_STEPS = [
    STEP_NAMES.FuelType,
    STEP_NAMES.Transmission
];

export const dataParser = ({ data, type }) => {
    if (LEAD_FORM_STEPS.includes(type)) return { [type]: { ...data } };
    return data;
};

export const generateLeadTokenPayload = ({ leadToken, stableID, leadData, gaId = "" }) => {
    return {
        client_id: stableID || gaId,
        data: {
            ...leadData,
            ...(leadToken && { token: leadToken })
        }
    };
};

export const getUpdatedLeadFormData = ({ data, type, leadData = {}, stepper, tabs, tabData, registrationNo, resetValues }) => {
    const parsedData = dataParser({ data, type });
    const currentNode = findStep({ list: stepper, stepName: type });
    const isVariant = type === STEP_NAMES.Variant;
    const fuelType = {
        displayText: data.variantType
    };

    const updatedLeadData = LEAD_FORM_STEPS.includes(type) ? { ...leadData, ...parsedData } : updateLeadFormState({
        state: leadData,
        previousStep: type,
        value: parsedData,
        nextStep: currentNode.next.value.name,
        tabs,
        tabData,
        registrationNo,
        resetValues
    });
    if (LEAD_FORM_VARIANT_STEPS.includes(type) && isDataChanged(data.displayText, leadData[type].displayText)) {
        updatedLeadData.variant = { displayText: "", id: null, variantType: null };
    }
    const isModelPresent = !!(updatedLeadData && updatedLeadData.model && updatedLeadData.model.id);
    if (isVariant) return { ...updatedLeadData, fuelType };
    return { ...updatedLeadData, ...(!isModelPresent && { fuelType: {}, variant: { displayText: "", id: null, variantType: null } }) };
};
