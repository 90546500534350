import { createTypes } from "../../../utils/redux/create-types";

export default createTypes(
    `
    SET_BRAND
    SET_MODEL
    SET_YEAR
    SET_REGISTRATION_STATE
    SET_VARIANT
    SET_BRANCH
    SET_KILOMETER_DRIVEN
    SET_CURRENT_STEP
    SET_RTO
    MAKE_LEAD
    MAKE_LEAD_SUCCESS
    MAKE_LEAD_FAILURE
    SHOW_PROCEED_BUTTON
    SET_FLOW_NAME
    ACTIVATE_LAST_STEP

    MERGE_LEAD_DATA

    RESET_LEAD_FORM_DATA

    SET_IS_TRUE_CALLER
    SET_IS_TRUE_CALLER_CANCELLED

    UPDATE_CTA_LOCATION

    UPDATE_PROCEED_CTA_STATUS

    UPDATE_EXPERIMENTAL_CTA_CLICK_STATUS

    SET_TUTORIAL_STATUS
    SET_ACKO_EXTRAS_FOR_APPOINTMENT

    CAR_CONDITION_TYPE
    SET_IS_WEB_QUOTE_ACCEPTED
    SET_ACTIVE_SCREEN
    SET_FGVGE_SCREEN
    RESET_ALL_SCREEN
    USER_EXPECTED_PRICE
    SET_USER_ADDITIONAL_QUESTIONS
    USER_FAIR_CAR_PRICE_REASON
    SET_USER_CAR_REGISTRATION_NUMBER
    SET_SHOW_CONFIRM_DETAIL_MODAL
    SET_FUEL_TYPE
    SET_TRANSMISSION_TYPE
    SET_VARIANT_ACTIVE_SCREEN
    SET_IS_SELL_ONLINE
    SET_OWNER_NAME
    SET_MODAL_VALUE
    SET_LEAD_SCORE
    ASK_USER_INTENT
    SET_USER_INTENT
    SET_NARROW_PRICE_EXPERIMENT
    SET_SIMILAR_CARS
    SET_SELL_MKT_USER_DROP
    SET_STATE_ACTIVE_SCREEN
    SET_LEAD_FORM_DATA
    SHOW_CAR_LOADER
    SET_SELECTED_CITY_ID
    SET_STATSIG_TEXT
    SET_USER_FINANCE
    UPDATE_LEAD_DATA
`,
    {
        prefix: "leadform/"
    }
);
