/* eslint-disable consistent-return */
/* eslint-disable max-statements */
/* eslint-disable camelcase */
import { createReducer } from "../../../utils/redux/create-reducer";
import Types from "./types";
// import { CONFIRMATION_TAB, TAB_DATA } from "../../../utils/funnel/steps.mobile";
import { STEP_NAMES, CURRENT_REG_SCREEN, LEAD_FORM } from "../../../constants/app-constants";
import parsePriceRanges from "../../../utils/parsers/parse-price-ranges";
// import { findStep } from "../../../utils/funnel/stepper";
import { FUNNEL_CONFIG } from "../../../utils/funnel/steps.mobile";
import { getKmsRange, getStepindex, getTransmissionType, isDataChanged, updateLeadFormState } from "./selector";

// const isDataChanged = (before, after) => {
//     return before !== after;
// };

// const getStepindex = (currentStep, tabs = TABS) => {
//     const currentTab = tabs.filter(tab =>
//         currentStep.toLowerCase().includes(tab.name.toLowerCase())
//     );
//     return currentTab[0].index;
// };

// const resetTabValues = (currentStepIndex, { tabData = TAB_DATA, registrationNo }) => {
//     const tabList = Object.keys(tabData);
//     const precedingValues = {};
//     for (let index = currentStepIndex; index <= tabList.length; index++) {
//         if (tabList[index - 1] === STEP_NAMES.Variant) {
//             precedingValues[tabList[index - 1]] = { displayText: "", id: null, variantType: null };
//         } else if (tabList[index - 1] === STEP_NAMES.Year) {
//             precedingValues[tabList[index - 1]] = { displayText: "", id: null, year_id: null };
//         } else if (tabList[index - 1] === STEP_NAMES.State) {
//             if (registrationNo && !registrationNo.userCarRegistrationNo) {
//                 precedingValues[tabList[index - 1]] = { displayText: "", id: null };
//             }
//         } else if (tabList[index - 1] === STEP_NAMES.Branch) {
//             precedingValues[tabList[index - 1]] = {
//                 displayText: "",
//                 id: null,
//                 selectionType: "city"
//             };

//         } else {
//             precedingValues[tabList[index - 1]] = { displayText: "", id: null };

//             if (currentStepIndex < NUMBER.FIVE) {
//                 precedingValues[STEP_NAMES.FuelType] = { displayText: "" };
//                 precedingValues[STEP_NAMES.Transmission] = { displayText: "" };
//                 precedingValues.variantActiveScreen = "fueltype";

//             }
//         }
//     }
//     const activateLastStep = null;
//     const askUserIntent = null;
//     const userIntent = null;
//     return { ...precedingValues, activateLastStep, askUserIntent, userIntent };
// };

// const updateLeadFormState = ({ state, previousStep, value, nextStep, tabs, tabData, registrationNo }) => {
//     let currentStepIndex = state.currentStepIndex;
//     let resettedTabValues = {};
//     const dataChanged = isDataChanged(state[previousStep].id, value.id);
//     let proceedCTA = state.proceedCTA;
//     if (dataChanged) {
//         proceedCTA = false;
//         currentStepIndex = getStepindex(nextStep, tabs);
//         resettedTabValues = resetTabValues(currentStepIndex, { tabData, registrationNo });
//     }
//     return {
//         ...state,
//         [previousStep]: value,
//         ...resettedTabValues,
//         currentStepIndex,
//         proceedCTA,
//         isLeadFormChanged: dataChanged
//     };
// };

export const INITIAL_STATE = {
    brand: { displayText: "", id: null },
    model: { displayText: "", id: null },
    fuelType: { displayText: "" },
    ownerName: "",
    transmission: { displayText: "" },
    year: { displayText: "", id: null, year_id: null },
    variant: { displayText: "", id: null, variantType: null },
    registrationState: { displayText: "", id: null },
    branch: { displayText: "", id: null, selectionType: "city" },
    rto: { displayText: "", id: null },
    currentStep: STEP_NAMES.Brand,
    kilometersDriven: { displayText: "", id: null },
    currentStepIndex: getStepindex(STEP_NAMES.Brand),
    currentTabIndex: getStepindex(STEP_NAMES.Brand),
    prevTabIndex: 0,
    proceedCTA: false,
    phoneNumber: { displayText: "", id: null },
    token: null,
    error: null,
    activateLastStep: false,
    priceRange: {},
    source: null,
    isLeadFormChanged: false,
    isTruecaller: false,
    isTruecallerCancelled: false,
    isFocusOrBlurInput: false,
    flowName: "default",
    ctaLocation: null,
    experimentalT0CTAClicked: false,
    isAllStepFill: false,
    stepValue: 5000,
    isTutorialEnabled: true,
    ackoAppointmentExtras: {},
    whatsappConsent: null,
    isWebQuoteAccepted: null,
    activeScreen: "carCondition",
    fgvgeScreen: "offerPriceAcceptionChoice",
    additionalQuestions: [],
    userExpectedPrice: "",
    fairCarReasons: [],
    carRegistrationNo: "",
    variantActiveScreen: "fueltype",
    isSellOnline: false,
    leadScore: null,
    askUserIntent: false,
    userIntent: null,
    narrowExperimentType: "",
    similarCars: [],
    sellMktUserDrop: "NOT_ELIGIBLE",
    stateActiveScreen: CURRENT_REG_SCREEN.STATE,
    showCarLoader: false,
    selectedCityId: null,
    statsigText: null,
    sellerFinance: "",
    isFetchedOnSSR: false
};

// export const setBrand = (state = INITIAL_STATE, { brand, stepper, tabs, tabData, registrationNo }) => {
//     const currentNode = findStep({ list: stepper, stepName: STEP_NAMES.Brand });
//     return updateLeadFormState({
//         state,
//         previousStep: STEP_NAMES.Brand,
//         value: brand,
//         nextStep: currentNode.next.value.name,
//         tabs,
//         tabData,
//         registrationNo
//     });
// };

// export const setBranch = (state = INITIAL_STATE, { branch, tabs, stepper, tabData, registrationNo }) => {
//     const currentNode = findStep({ list: stepper, stepName: STEP_NAMES.Branch });
//     return updateLeadFormState({
//         state,
//         previousStep: STEP_NAMES.Branch,
//         value: branch,
//         nextStep: currentNode.next.value.name,
//         tabs,
//         tabData,
//         registrationNo
//     });
// };

// export const setModel = (state = INITIAL_STATE, { model, stepper, tabs, tabData, registrationNo }) => {
//     const currentNode = findStep({ list: stepper, stepName: STEP_NAMES.Model });
//     return updateLeadFormState({
//         state,
//         previousStep: STEP_NAMES.Model,
//         value: model,
//         nextStep: currentNode.next.value.name,
//         tabs,
//         tabData,
//         registrationNo
//     });
// };

// export const setYear = (state = INITIAL_STATE, { year, registrationNo }) => {
//     return updateLeadFormState({
//         state,
//         previousStep: STEP_NAMES.Year,
//         value: year,
//         nextStep: STEP_NAMES.Model,
//         registrationNo
//     });
// };

// export const setVariant = (state = INITIAL_STATE, { variant, registrationNo }) => {
//     const { registrationState, rto } = state;
//     const nextStep = (
//         registrationNo &&
//         registrationNo.userCarRegistrationNo &&
//         registrationState.id &&
//         rto.id
//     ) ? STEP_NAMES.Kms : STEP_NAMES.State;
//     return updateLeadFormState({
//         state,
//         previousStep: STEP_NAMES.Variant,
//         value: variant,
//         nextStep,
//         registrationNo
//     });
// };

// export const setRegistrationState = (state = INITIAL_STATE, { registrationState }) => {
//     let { currentStepIndex, currentTabIndex, prevTabIndex } = state;
//     let resettedTabValues = {};
//     const dataChanged = isDataChanged(state.registrationState.id, registrationState.id);
//     if (dataChanged) {
//         currentStepIndex = CONFIRMATION_TAB.index;
//         resettedTabValues = resetTabValues(currentStepIndex, { tabData: TAB_DATA });
//     }
//     prevTabIndex = currentTabIndex;
//     currentTabIndex = CONFIRMATION_TAB.index;
//     return {
//         ...state,
//         registrationState,
//         currentStepIndex,
//         prevTabIndex,
//         ...resettedTabValues
//     };
// };

export const setKilometerDriven = (state = INITIAL_STATE, { kilometersDriven }) => {
    return {
        ...state,
        kilometersDriven,
        currentStepIndex: 7,
        isLeadFormChanged: isDataChanged(state.kilometersDriven.id, kilometersDriven.id)
    };
};

export const setStep = (state = INITIAL_STATE, { currentStep, tabs }) => {
    const prevTabIndex = state.currentTabIndex;
    const currentTabIndex = getStepindex(currentStep, tabs);
    return { ...state, currentStep, currentTabIndex, prevTabIndex };
};

export const makeLeadSuccess = (state = INITIAL_STATE, { data }) => {
    return {
        ...state,
        token: data.token,
        priceRange: parsePriceRanges(data),
        error: null
    };
};

export const makeLead = (state = INITIAL_STATE) => {
    return { ...state, error: null };
};

export const makeLeadFailure = (state = INITIAL_STATE, { data }) => {
    return { ...state, token: null, error: data };
};

export const showProceedButton = (state = INITIAL_STATE) => {
    return { ...state, proceedCTA: true };
};

export const activateLastStep = (state = INITIAL_STATE, { data }) => {
    return { ...state, activateLastStep: data };
};

export const resetLeadFormData = (state = INITIAL_STATE, { clearToken }) => {
    const { sellMktUserDrop, token } = state || {};
    return { ...state, ...INITIAL_STATE, sellMktUserDrop,
        ...(clearToken && {token})
    };
};

export const mergeLeadData = (state = INITIAL_STATE, { data }) => {
    const {
        token,
        make_id, make_display,
        model_id, model_display,
        year, year_id,
        variant_id, variant_display_name,
        state_id, state_code,
        kms, kms_range,
        rto_id,
        cta_location,
        rto_code = "NA",
        stepper_value,
        lead_type,
        whatsapp_consent,
        language,
        fuel_type = "",
        transmission_type = ""
    } = data;

    return {
        ...state,
        brand: { id: make_id, displayText: make_display },
        model: { id: model_id, displayText: model_display },
        year: { id: year, displayText: year.toString(), year_id },
        variant: { id: variant_id, displayText: variant_display_name },
        registrationState: { id: state_id, displayText: state_code },
        priceRange: parsePriceRanges(data),
        kilometersDriven: { id: kms, displayText: kms_range || getKmsRange(kms) },
        token,
        currentStepIndex: 7,
        prevTabIndex: 6,
        currentStep: "kms",
        ...(rto_id && { rto: { displayText: rto_code, id: rto_id } }),
        ...(cta_location && { ctaLocation: cta_location }),
        isLeadFormChanged: false,
        source: lead_type,
        stepValue: isNaN(stepper_value) ? INITIAL_STATE.stepValue : stepper_value,
        whatsappConsent: whatsapp_consent,
        language,
        ...(transmission_type && { transmission: { displayText: getTransmissionType(transmission_type) }}),
        ...(fuel_type && { fuelType: { displayText: fuel_type }})
    };
};

export const setIsTrueCaller = (state = INITIAL_STATE, { isTruecaller }) => {
    return { ...state, isTruecaller };
};

export const setIsTrueCallerCancelled = (state = INITIAL_STATE, { isTruecallerCancelled }) => {
    return { ...state, isTruecallerCancelled };
};

export const setFlowName = (state = INITIAL_STATE, { flowName }) => {
    return { ...state, flowName };
};

export const updateCTALocation = (state = INITIAL_STATE, { ctaLocation }) => {
    return { ...state, ctaLocation };
};

export const setRTO = (state = INITIAL_STATE, { rto }) => {
    return {
        ...state, rto,
        nextStep: STEP_NAMES.Kms
    };
};

export const allStepFilled = (state = INITIAL_STATE, { isAllStepFill }) => {
    return { ...state, isAllStepFill };
};

export const updateProceedCTAStatus = (state = INITIAL_STATE, { proceedCTA }) => {
    return { ...state, proceedCTA };
};

export const updateExperimentalT0CTAClickStatus = (
    state = INITIAL_STATE, { experimentalT0CTAClicked }) => {
    return { ...state, experimentalT0CTAClicked };
};

export const setTutorialStatus = (state = INITIAL_STATE, { isTutorialEnabled }) => {
    return { ...state, isTutorialEnabled };
};

export const setAckoExtrasForAppointment = (state = INITIAL_STATE, { ackoAppointmentExtras }) => {
    return { ...state, ackoAppointmentExtras };
};

export const setCarCondition = (state = INITIAL_STATE, { condition }) => {
    return {
        ...state,
        carCondition: condition
    };
};

export const setIsWebQuoteAccepted = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        isWebQuoteAccepted: action.isWebQuoteAccepted
    };
};

export const activeScreenHandler = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        activeScreen: action.activeScreen
    };
};

export const setFgvgeScreenHandler = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        fgvgeScreen: action.fgvgeScreen
    };
};

export const resetAllScreen = (state = INITIAL_STATE) => {
    return {
        ...state,
        fgvgeScreen: "offerPriceAcceptionChoice",
        activeScreen: "carCondition",
        userExpectedPrice: "",
        fairCarReasons: [],
        additionalQuestions: []

    };
};

export const setUserExpectedPrice = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        userExpectedPrice: action.expectedPrice
    };
};

export const setUserFailrCarReasons = (state = INITIAL_STATE, action) => {
    return {
        ...state,
        fairCarReasons: action.reasons
    };
};

export const setUserAdditionalQuestion = (state = INITIAL_STATE, { additionalQuestions }) => {
    return {
        ...state,
        additionalQuestions
    };
};

export const setUserCarRgistrationNo = (state = INITIAL_STATE, { carRegistrationNo }) => {
    return {
        ...state,
        carRegistrationNo
    };
};

export const setFuelType = (state = INITIAL_STATE, { carFuelType }) => {
    return {
        ...state,
        fuelType: {
            displayText: carFuelType
        }
    };
};

export const setOwnerName = (state = INITIAL_STATE, { ownerName }) => {
    return {
        ...state,
        ownerName
    };
};

export const setTransmission = (state = INITIAL_STATE, { transmission }) => {
    return {
        ...state,
        transmission: {
            displayText: transmission
        }
    };
};

export const setVariantActiveScreen = (state = INITIAL_STATE, { variantActiveScreen }) => {
    return {
        ...state,
        variantActiveScreen
    };
};

export const setIsSellOnline = (state = INITIAL_STATE, { flag }) => {
    return { ...state, isSellOnline: flag };
};

export const setLeadScore = (state = INITIAL_STATE, { leadScore }) => {
    return { ...state, leadScore };
};

export const askUserIntent = (state = INITIAL_STATE, { flag }) => {
    return { ...state, askUserIntent: flag };
};

export const userIntent = (state = INITIAL_STATE, { intent }) => {
    return { ...state, userIntent: intent };
};

export const setNarrowPriceExperiment = (state = INITIAL_STATE, { experiment }) => {
    return { ...state, narrowExperimentType: experiment };
};

export const setSimilarCars = (state = INITIAL_STATE, { similarCars }) => {
    return { ...state, similarCars };
};

export const setSellMktUserDrop = (state = INITIAL_STATE, { sellMktUserDrop }) => {
    return { ...state, sellMktUserDrop };
};

export const setStateActiveScreen = (state = INITIAL_STATE, { stateActiveScreen }) => {
    return {
        ...state,
        stateActiveScreen
    };
};

export const setFormData = (state = INITIAL_STATE, { details }) => {
    const {
        brand,
        brandId,
        model,
        modelId,
        variant,
        variantId,
        year,
        yearId,
        fuelType,
        rtoCode,
        rtoId,
        transmissionType,
        stateCode,
        stateId,
        registrationNumber,
        responseLeadData
    } = details || {};
    const stepsData = {
        [STEP_NAMES.Brand]: { displayText: brand, id: brandId },
        [STEP_NAMES.Year]: { displayText: year, id: year, year_id: parseInt(yearId) },
        [STEP_NAMES.Model]: { displayText: model, id: modelId },
        [STEP_NAMES.Variant]: { displayText: variant, id: variantId, variantType: transmissionType },
        [STEP_NAMES.State]: { displayText: stateCode, id: stateId },
        [STEP_NAMES.KilometerDriven]: { displayText: responseLeadData && responseLeadData.kms, id: responseLeadData && responseLeadData.kms }
    };
    const tempState = {
        ...state,
        ...stepsData,
        rto: { displayText: rtoCode, id: rtoId },
        fuelType: {displayText: fuelType},
        transmission: { displayText: transmissionType },
        currentStepIndex: 7,
        carRegistrationNo: registrationNumber
    };
    return {
        ...(updateLeadFormState({
            state: tempState,
            previousStep: STEP_NAMES.State,
            value: { id: stateId, displayText: stateCode },
            nextStep: STEP_NAMES.Kms,
            registrationNo: registrationNumber,
            tabs: FUNNEL_CONFIG[LEAD_FORM.DEFAULT].tabs,
            tabData: {
                ...stepsData,
                [STEP_NAMES.City]: { displayText: "", id: null },
                [STEP_NAMES.FuelType]: { displayText: "", id: null },
                [STEP_NAMES.RTO]: { displayText: "", id: null },
                [STEP_NAMES.Transmission]: { displayText: "", id: null }
            }
        })),
        isLeadFormChanged: true,
        ownerName: ""
    };
};

export const setShowCarLoader = (state = INITIAL_STATE, { flag }) => {
    return {
        ...state,
        showCarLoader: flag
    };
};

export const setSelectedCityId = (state = INITIAL_STATE, { id }) => {
    const isCityIdChanged = state.selectedCityId !== id;
    return {
        ...state,
        selectedCityId: id,
        ...(isCityIdChanged && {
            userIntent: null
        })
    };
};

export const setStatsigText = (state = INITIAL_STATE, { text }) => {
    return { ...state, statsigText: text };
};

export const setSellerFinance = (state = INITIAL_STATE, { option }) => {
    return { ...state, sellerFinance: option };
};

export const updateLeadData = (state = INITIAL_STATE, {data}) => {
    return {...state, ...data};
};

export const HANDLERS = {
    // [Types.SET_BRAND]: setBrand,
    // [Types.SET_MODEL]: setModel,
    // [Types.SET_YEAR]: setYear,
    // [Types.SET_VARIANT]: setVariant,
    // [Types.SET_REGISTRATION_STATE]: setRegistrationState,
    [Types.SET_CURRENT_STEP]: setStep,
    // [Types.SET_BRANCH]: setBranch,
    [Types.SET_KILOMETER_DRIVEN]: setKilometerDriven,
    [Types.MAKE_LEAD]: makeLead,
    [Types.MAKE_LEAD_SUCCESS]: makeLeadSuccess,
    [Types.MAKE_LEAD_FAILURE]: makeLeadFailure,
    [Types.SHOW_PROCEED_BUTTON]: showProceedButton,
    [Types.ACTIVATE_LAST_STEP]: activateLastStep,
    [Types.MERGE_LEAD_DATA]: mergeLeadData,
    [Types.RESET_LEAD_FORM_DATA]: resetLeadFormData,
    [Types.SET_IS_TRUE_CALLER]: setIsTrueCaller,
    [Types.SET_IS_TRUE_CALLER_CANCELLED]: setIsTrueCallerCancelled,
    [Types.SET_FLOW_NAME]: setFlowName,
    [Types.UPDATE_CTA_LOCATION]: updateCTALocation,
    [Types.SET_RTO]: setRTO,
    [Types.SET_MODAL_VALUE]: allStepFilled,
    [Types.UPDATE_PROCEED_CTA_STATUS]: updateProceedCTAStatus,
    [Types.UPDATE_EXPERIMENTAL_CTA_CLICK_STATUS]: updateExperimentalT0CTAClickStatus,
    [Types.SET_TUTORIAL_STATUS]: setTutorialStatus,
    [Types.SET_ACKO_EXTRAS_FOR_APPOINTMENT]: setAckoExtrasForAppointment,
    [Types.CAR_CONDITION_TYPE]: setCarCondition,
    [Types.SET_IS_WEB_QUOTE_ACCEPTED]: setIsWebQuoteAccepted,
    [Types.SET_ACTIVE_SCREEN]: activeScreenHandler,
    [Types.SET_FGVGE_SCREEN]: setFgvgeScreenHandler,
    [Types.RESET_ALL_SCREEN]: resetAllScreen,
    [Types.USER_EXPECTED_PRICE]: setUserExpectedPrice,
    [Types.USER_FAIR_CAR_PRICE_REASON]: setUserFailrCarReasons,
    [Types.SET_USER_ADDITIONAL_QUESTIONS]: setUserAdditionalQuestion,
    [Types.SET_USER_CAR_REGISTRATION_NUMBER]: setUserCarRgistrationNo,
    [Types.SET_FUEL_TYPE]: setFuelType,
    [Types.SET_OWNER_NAME]: setOwnerName,
    [Types.SET_TRANSMISSION_TYPE]: setTransmission,
    [Types.SET_VARIANT_ACTIVE_SCREEN]: setVariantActiveScreen,
    [Types.SET_IS_SELL_ONLINE]: setIsSellOnline,
    [Types.SET_LEAD_SCORE]: setLeadScore,
    [Types.ASK_USER_INTENT]: askUserIntent,
    [Types.SET_USER_INTENT]: userIntent,
    [Types.SET_NARROW_PRICE_EXPERIMENT]: setNarrowPriceExperiment,
    [Types.SET_SIMILAR_CARS]: setSimilarCars,
    [Types.SET_SELL_MKT_USER_DROP]: setSellMktUserDrop,
    [Types.SET_STATE_ACTIVE_SCREEN]: setStateActiveScreen,
    [Types.SET_LEAD_FORM_DATA]: setFormData,
    [Types.SHOW_CAR_LOADER]: setShowCarLoader,
    [Types.SET_SELECTED_CITY_ID]: setSelectedCityId,
    [Types.SET_STATSIG_TEXT]: setStatsigText,
    [Types.SET_USER_FINANCE]: setSellerFinance,
    [Types.UPDATE_LEAD_DATA]: updateLeadData
};

export default createReducer(INITIAL_STATE, HANDLERS);
